import { combineReducers, configureStore } from '@reduxjs/toolkit';

import AlertsSlice from './slices/AlertsSlice';
import UserSlice from './slices/UserSlice';
import LayoutSlice from './slices/LayoutSlice';
import QueryResultSlice from './slices/QueryResultSlice';

const store = configureStore({
  reducer: combineReducers({ Alerts: AlertsSlice, User: UserSlice, Layout: LayoutSlice, QueryResult: QueryResultSlice })
});

export const { dispatch, getState } = store;

export default store;
