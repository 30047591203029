import config from './config';

export const perPageItems = [
  {
    value: 10,
    valueToshow: 10
  },
  {
    value: 25,
    valueToshow: 25
  },
  {
    value: 50,
    valueToshow: 50
  },
  {
    value: 100,
    valueToshow: 100
  },
  {
    value: 500,
    valueToshow: 500
  }
];
export const fileTypesColDefs = [
  {
    headerName: 'File Id',
    field: 'file_id',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    floatingFilterComponentParams: { suppressFloatingFilterButton: true },
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    headerName: 'File Name',
    field: 'file_name'
  },
  {
    headerName: 'File description ',
    field: 'file_description'
  },
  {
    headerName: 'File Actual Name',
    field: 'file_actual_name'
  },
  {
    headerName: 'File Size',
    field: 'file_size_kb',
    sortable: false
  },
  {
    headerName: 'File Uploaded By Id',
    field: 'file_uploaded_by_id'
  },
  {
    headerName: 'File Extension',
    field: 'file_extension'
  },

  {
    headerName: 'File Link',
    field: 'file_link',
    cellRenderer: 'UrlRenderer',
    sortable: false
  },

  { headerName: 'Actions', field: 'actions', colId: 'actions', cellRenderer: 'ActionRenderer', sortable: false, minWidth: 150 }
];
export const fileTypesColDefs1 = [
  {
    headerName: 'File Id',
    field: 'file_id',
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    headerName: 'File Name',
    field: 'file_name'
  },
  {
    headerName: 'File description ',
    field: 'file_description'
  },
  {
    headerName: 'File Actual Name',
    field: 'file_actual_name'
  },
  {
    headerName: 'File Size',
    field: 'file_size_kb',
    sortable: false
  },
  {
    headerName: 'File Uploaded By Id',
    field: 'file_uploaded_by_id'
  },
  {
    headerName: 'File Extension',
    field: 'file_extension'
  },

  {
    headerName: 'File Link',
    field: 'file_link',
    sortable: false
  },

  { headerName: 'Actions', field: 'actions', colId: 'actions', cellRenderer: 'ActionRenderer', sortable: false, minWidth: 150 }
];

export const fileManagmentAccessColDefs = [
  {
    headerName: 'Id',
    field: 'id',
    colId: 'id',
    maxWidth: 100
  },
  {
    headerName: 'Entity Id',
    field: 'user_guid',
    colId: 'user_guid',
    editable: true
  },
  {
    headerName: 'Actions',
    field: 'actions',
    colId: 'actions',
    cellRenderer: 'ActionRenderer',
    sortable: false
  }
];

export const downloadsColDef = [
  {
    headerName: 'Id',
    field: 'id',
    maxWidth: 100
  },
  {
    headerName: 'Name',
    field: 'name',
    maxWidth: 100
  },
  {
    headerName: 'Description',
    field: 'description',

    editable: true,
    minWidth: 300
  }
];
export const userType = {
  superAdmin: 1,
  admin: 2,
  user: 3
};
export const allowedTypes = ['pdf', 'txt', 'csv', 'xls', 'xlsx', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'gif'];
export const maxSize = 5 * 1024 * 1024;
export const getRedirectUrl = () => {
  if (window.location.href.includes('/forbidden')) {
    return `${config.AUTH_FRONTEND_URL}?redirect_url=${config.FILE_MICROSERVICE_URL}`;
  }
  return `${config.AUTH_FRONTEND_URL}?redirect_url=${window.location.href}`;
};

export const tableStructureColDefs = [
  {
    headerName: 'Column Name',
    field: 'name',
    minWidth: 100
  },
  {
    headerName: 'Data Type',
    field: 'type',
    minWidth: 40
  },
  {
    headerName: 'Character Maxmimum Length',
    field: 'length',
    minWidth: 40
  }
];
