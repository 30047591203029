import { dispatch } from 'app/reducers/configureStore';
import axios from '../../utils/axios';
import { setHideBeatLoader, setShowBeatLoader } from 'app/reducers/slices/AlertsSlice';
import { handleError } from 'helperFunctions/handleError';
import { setSnackBarAlert } from '../../reducers/slices/AlertsSlice';
import config from 'app/config/config';
import { downloadFiles } from 'app/utils/helpers';
import { setUserData } from 'app/reducers/slices/UserSlice';
import { perPageItems } from 'app/config/Constants';
const filesFormatter = (files) => {
  return files.map((file) => ({
    file_id: file.file_id,
    file_name: file.file_name,
    file_description: file.file_description,
    file_actual_name: file.file_actual_name,
    file_size_kb: formatFileSize(file.file_size_kb),
    file_uploaded_by_id: file.file_uploaded_by_id,
    file_extension: file.file_extension,
    file_link: file.file_link,
    public_access_token_url_suffix: file.public_access_token_url_suffix
  }));
};

const formatFileSize = (fileSizeKB) => {
  if (fileSizeKB < 1024) {
    return `${Math.round(fileSizeKB)} KB`;
  } else if (fileSizeKB < 1024 * 1024) {
    const fileSizeMB = fileSizeKB / 1024;
    return `${fileSizeMB.toFixed(2)} MB`;
  } else {
    const fileSizeGB = fileSizeKB / (1024 * 1024);
    return `${fileSizeGB.toFixed(2)} GB`;
  }
};

const getFileFormatter = (file) => {
  return {
    file_id: file.file_id,
    file_actual_name: file.file_actual_name,
    file_ext: file.file_ext,
    file_description: file.file_description,
    public_access_token_url_suffix: file.public_access_token_url_suffix,
    file_upload_pending: file.file_upload_pending
  };
};

const userDetailFormatter = (user) => {
  return {
    type: user.type
  };
};

export const listFiles = async (params) => {
  const body = {
    page: params.pageNo ?? 1,
    page_size: params.pageSize ?? perPageItems[0]?.value,
    sorts: params?.sort ?? [],
    filters: params?.filter ?? []
  };
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.post('/api/file/listing', body);

    if (data?.status) {
      const responseData = {
        page: data.page,
        count: data.count,
        totalCount: data.total_count,
        totalPageCount: data.total_page_count,
        data: filesFormatter(data.data)
      };
      return responseData;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const getMediabyFileId = async (params) => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`/api/file/${params.file_id}/${params.salt}`);

    if (data?.status) {
      return getFileFormatter(data.data);
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const singleFileUpload = async (formdata, fileId, salt) => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.patch(`/api/file/update/${fileId}/${salt}`, formdata, {
      rawRequest: true,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Uploaded successfully', severity: 'success' }));
      return true;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const fileDownload = async (params) => {
  try {
    dispatch(setShowBeatLoader());

    const response = await axios.get(`/api/file/download/${params.file_id}/${params.salt}`, { responseType: 'blob' });

    const contentType = response.headers['content-type'];

    if (contentType.includes('application/json')) {
      let jsonData = await response.data.text();
      jsonData = JSON.parse(jsonData);
      dispatch(setSnackBarAlert({ message: jsonData?.message, severity: 'error' }));
    } else if (response.headers['content-disposition']) {
      downloadFiles(response);

      dispatch(setSnackBarAlert({ message: 'Downloaded successfully', severity: 'success' }));
      return true;
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};
export const bulkFileDownload = async (body) => {
  try {
    dispatch(setShowBeatLoader());
    const response = await axios.post(`api/file/download/bulk`, { file_ids: body }, { responseType: 'blob' });

    const contentType = response.headers['content-type'];

    if (contentType.includes('application/json')) {
      let jsonData = await response.data.text();
      jsonData = JSON.parse(jsonData);
      dispatch(setSnackBarAlert({ message: jsonData?.message, severity: 'error' }));
    } else if (response.headers['content-disposition']) {
      downloadFiles(response);

      dispatch(setSnackBarAlert({ message: 'Downloaded successfully', severity: 'success' }));
      return true;
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const getUserDetails = async () => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`${config.AUTH_BACKEND_URL}/api/user_details`);

    if (data?.status) {
      dispatch(setUserData(userDetailFormatter(data.data)));
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};

export const deleteMediabyId = async (body) => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.delete(`/api/file/admin/hard_delete`, { data: { file_ids: body } });

    if (data?.status) {
      dispatch(setSnackBarAlert({ message: 'Deleted successfully', severity: 'success' }));
      return true;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};
export const logout = async () => {
  try {
    dispatch(setShowBeatLoader());
    const { data } = await axios.get(`${config.AUTH_BACKEND_URL}/api/logout`);

    if (data?.status) {
      return true;
    } else {
      dispatch(setSnackBarAlert({ message: data.message, severity: 'error' }));
    }
  } catch (error) {
    handleError(error);
  } finally {
    dispatch(setHideBeatLoader());
  }
};
