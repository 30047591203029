import get from 'lodash/get';

const setTokenInLocalStorage = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
  }
};

const errorMessageHandler = (responseData) => {
  let errorArray = [];
  if (responseData?.errors?.length) {
    responseData.errors.map((e) => errorArray.push(e.message));
  } else if (responseData?.message) {
    errorArray.push(responseData.message);
  } else {
    errorArray.push('Something went wrong!');
  }
  return errorArray;
};

const downloadFiles = (response) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = `${get(response, 'headers.content-disposition', 'files')}`.replace(/"/g, '').split('=')[1];

  link.setAttribute('download', fileName.toString());
  document.body.appendChild(link);
  link.click();
};

const getFileExtension = (fileName) => {
  return fileName?.split('.')?.pop()?.toLowerCase();
};

export { setTokenInLocalStorage, errorMessageHandler, downloadFiles, getFileExtension };
