import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Paper, Slide } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { setFeedbackObj } from 'app/reducers/slices/AlertsSlice';

const FeedbackSlider = () => {
  const [toogleSlider, setToogleSlider] = useState(false);
  const feedbackObj = useSelector((state) => state.Alerts.feedbackObj);
  const dispatch = useDispatch();

  const toogleSliderHandler = useCallback(() => {
    setToogleSlider((prevState) => !prevState);
  }, []);

  const clearFeedbackHandler = useCallback(() => {
    dispatch(setFeedbackObj(null));
    setToogleSlider(false);
  }, [dispatch]);

  useEffect(() => {
    if (feedbackObj) setToogleSlider(true);
  }, [feedbackObj]);

  return (
    <>
      {feedbackObj && <ExpandLess toogleSliderHandler={toogleSliderHandler} />}

      <Slide direction="up" in={toogleSlider} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            zIndex: 1450,
            borderRadius: '10px 10px 0 0',
            width: '100%'
          }}
        >
          <Paper
            sx={{
              padding: '0 20px',
              maxHeight: '50vh',
              minHeight: '40vh',
              overflow: 'auto',
              backgroundColor: '#E1DFCF'
            }}
          >
            <pre>{JSON.stringify(feedbackObj, null, 2)}</pre>
            <Button
              variant="contained"
              onClick={clearFeedbackHandler}
              size="medium"
              sx={{ position: 'absolute', right: 25, bottom: 15, textTransform: 'none', fontWeight: 'bold' }}
            >
              Clear
            </Button>
          </Paper>

          <ExpandMore toogleSliderHandler={toogleSliderHandler} />
        </Box>
      </Slide>
    </>
  );
};

export default FeedbackSlider;

const ExpandMore = memo(({ toogleSliderHandler }) => (
  <IconButton
    onClick={toogleSliderHandler}
    sx={{
      backgroundColor: 'rgb(25, 118, 210)',
      position: 'absolute',
      boxShadow: 'inset 0 0 0 2px white',
      top: -18,
      right: 25,
      '&:hover': {
        backgroundColor: 'rgb(25, 118, 210)'
      }
    }}
  >
    <ExpandMoreIcon sx={{ color: 'white' }} fontSize="large" />
  </IconButton>
));

const ExpandLess = memo(({ toogleSliderHandler }) => (
  <IconButton
    onClick={toogleSliderHandler}
    sx={{
      backgroundColor: 'rgb(25, 118, 210)',
      position: 'fixed',
      bottom: 10,
      right: 25,
      zIndex: 1400,
      '&:hover': {
        backgroundColor: 'rgb(25, 118, 210)'
      }
    }}
  >
    <ExpandLessIcon sx={{ color: 'white' }} fontSize="large" />
  </IconButton>
));
