import { CssBaseline } from '@mui/material';
import { MatxTheme } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import Router from './routes';
import NotificationAlerts from './components/alerts/NotificationAlerts';

const App = () => {
  return (
    <SettingsProvider>
      <MatxTheme>
        <NotificationAlerts />
        <CssBaseline />
        <Router />
      </MatxTheme>
    </SettingsProvider>
  );
};

export default App;
