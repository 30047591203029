import axios from 'axios';
import { get } from 'lodash';
import config from '../config/config';
import { setSnackBarAlert } from 'app/reducers/slices/AlertsSlice';
import { dispatch } from 'app/reducers/configureStore';
import { getRedirectUrl } from 'app/config/Constants';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = config.API_URL;
axiosInstance.defaults.withCredentials = true;

// Request Interceptor. All Request pass from here
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    if (axiosConfig.rawRequest) {
      // header for file_upload, video/audio will handle here i.e raw requests
    } else {
      axiosConfig.headers['Content-Type'] = 'application/json';
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (get(error, 'response.status', '') === 401) {
      window.location.href = getRedirectUrl();
    } else if (get(error, 'response.status', '') === 403) {
      window.location.href = `/forbidden`;
    } else if (error.message === 'Network Error' && !error.response) {
      dispatch(
        setSnackBarAlert({
          message: `It seems like you're currently offline. Please check your internet connection.`,
          severity: 'error'
        })
      );
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
