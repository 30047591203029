import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queryResponse: []
};

export const queryResultSlice = createSlice({
  name: 'queryResultSlice',
  initialState,
  reducers: {
    setQueryResponse: (state, action) => {
      state.queryResponse = action.payload;
    }
  }
});
export const { setQueryResponse } = queryResultSlice.actions;
export default queryResultSlice.reducer;
