import { lazy, useEffect, useRef } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import { getUserDetails } from './components/Files/ApiCalls';
import Cookies from 'js-cookie';

const FileListing = Loadable(lazy(() => import('app/views/FileListing')));
const FileManagementAccess = Loadable(lazy(() => import('./components/FileManagementAccess/FileManagementAccess')));
const FileUpload = Loadable(lazy(() => import('./components/Upload/FileUpload')));
const FileDownload = Loadable(lazy(() => import('./components/Download')));
const Forbidden = Loadable(lazy(() => import('./components/Forbidden')));
const Db = Loadable(lazy(() => import('./components/FilesDb/FilesDb')));

const adminRoutes = [
  { path: '/', element: <FileListing /> },
  { path: '/upload/:file_id/:salt', element: <FileUpload /> },
  { path: '/file-management', element: <FileManagementAccess /> },
  { path: '/shared/:file_id/:salt', element: <FileDownload /> },
  { path: '/forbidden', element: <Forbidden /> },
  { path: '/database-query', element: <Db /> }
];

const Router = () => {
  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    const pathname = locationRef.current.pathname;
    if (
      ['/', '/file-management', '/database-query'].includes(pathname) ||
      (['/upload', '/shared'].includes(pathname) && Cookies.get('access_token'))
    ) {
      getUserDetails();
    }
  }, []);

  const routes = useRoutes([
    {
      path: '/',
      element: <MatxLayout />,
      children: adminRoutes
    },

    { path: '*', element: <Navigate to="/" /> }
  ]);

  return routes;
};

export default Router;
